import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { BreadCrumb, Section } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
function ServicePage() {
  return (
    <Layout pageName="service">
      <SEO title="Project Management Services for Equipment Installation - WYN Technologies" description="WYN Technologies - providing end to end project management services for IT equipment and hardware installation with tailor made solutions. Contact us for the quote
" />
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link>Services</Link><Link to="/#">Project Management for Equipment Installations</Link>
              </BreadCrumb>
              <ServiceTitle>Project Management for Equipment Installations</ServiceTitle>
              <MonitoringSolutionsItemContent>
                Through our communication, professionalism, and resourcefulness, we can bring a fresh perspective to help cut cost and act as a technical resource when issues arise. Our experience with installations, remodels, de-installations, and scheduling for large scale, national, multi-site rollouts gives us the ability to help you to both reach your project goals and meet your project deadlines. Additionally, we offer onsite service calls, site surveys, and preventative maintenance to help keep your operations running smoothly and without worry.
              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
              <ImgWrapper>
                <StaticImage
                  src="../images/project-management.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </ImgWrapper>
            </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <OurSolution />
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <Faq />
      <LetastArticles />
    </Layout>
  )
}

export default ServicePage
